@import 'src/variables';

.ant-layout-header {
  .open-save-quiz-modal-button {
    margin-left: 1.5rem;
    font-style: normal;
    line-height: 1.375rem;
    text-align: center;
  }
}

.portfolio {
  margin-top: 4rem;

  .pagination {
    display: flex;
    justify-content: center;
  }

  .ant-space-vertical {
    width: 100%;
  }

  .quizzes {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;

    .ant-card-loading-block {
      background-color: $light-purple;
    }

    .card-skeleton {
      width: 100%;

      .card-skeleton-image {
        width: 100%;
        height: 11.313rem;
        background: $light-purple;

        svg {
          display: none;
        }
      }
    }

    .quiz-card-col {
      width: 20%;
    }

    @media screen and (max-width: map-get($grid-breakpoints, xl)) {
      .quiz-card-col {
        width: 25%;
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, lg)) {
      .quiz-card-col {
        width: 33%;
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      .quiz-card-col {
        width: 50%;
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, sm)) {
      .quiz-card-col {
        width: 100%;
      }
    }

    .no-quizzes {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 20%;

      .no-quizzes-text {
        line-height: 1.75rem;
        margin-top: 1.5rem;
        margin-bottom: 0.25rem;
      }

      .fill-the-empty-space-text {
        font-style: normal;
        line-height: 1.5rem;
      }

      .open-save-quiz-modal-button {
        margin-top: 1rem;
        height: 2.5rem;
        font-style: normal;
        line-height: 1.5rem;
        text-align: center;
      }
    }
  }

  .quiz-filter-xs {
    margin-bottom: 1rem;
  }

  .quiz-cards-container {
    justify-content: center;

    .sort-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          margin-left: auto;
        }
      }
    }

    .total-quizzes-count {
    }

    .sort-container {
      margin-bottom: 1.875rem;

      .sort {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .card-view-icon {
          margin-right: 1rem;
          cursor: pointer;
        }

        .list-view-icon {
          cursor: pointer;
        }
      }

      @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        .sort {
          justify-content: flex-start;
        }
      }
    }

    .sort-selector {
      .ant-select-selector {
        font-weight: bold;
      }
    }
  }
}
