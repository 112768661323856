@import 'src/variables';

.ant-layout-header {
  padding: 0;
  z-index: 1;
  background-color: white;
  height: fit-content;
  box-shadow: 0 1px 0 #f0f1f2;

  .header {
    justify-content: space-between;
    background-color: white;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;

    .header-content {
      display: flex;
      align-items: center;

      .product-by-schoolaby-box {
        line-height: initial;
      }
    }

    .product-by {
      font-family: Arial, serif;
      font-size: 0.625rem;
      line-height: 0.688rem;
      color: #bfbfbf;
    }

    .ant-menu {
      min-width: 100%;
    }

    .logo {
      float: left;
      height: 31px;
      margin: 1rem 0 1rem 0;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      line-height: 2.188rem;
      text-align: center;
      color: $primary-color;
      cursor: pointer;
    }

    .divider {
      height: 1.75rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .right-menu {
      float: right;

      .ant-dropdown-link {
        text-transform: uppercase;
        padding: 1rem;
      }

      .menu-item {
        display: inline-block;
      }
    }
  }
}
