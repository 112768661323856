@import 'src/variables';
@import 'src/global';

$edit-quiz-icon-size: 1.5rem;

.quiz-update {
  .header {
    @include component-x-padding;

    .last-saved {
      background-color: #fafafa;
      padding: 0.188rem;
      font-family: Inter, serif;
      font-size: 0.813rem;
      line-height: 1.25rem;
      color: #8c8c8c;
      white-space: nowrap;
    }

    .preview-button {
      margin-right: 0.875rem;
    }
  }

  .content {
    .content-row {
      .questions-col {
        padding-top: 0.5rem;
        @include component-x-padding;

        .questions-count-text {
          line-height: 1.75rem;
          margin-bottom: 1.5rem;
        }
      }

      .question-creation-col {
        position: relative;
        width: 33.333333vw;

        .question-creation-container {
          margin-top: -4rem;
          padding-top: 6.5rem;
          background-color: white;
          position: fixed;
          width: inherit;
          height: calc(100vh);

          .add-question-text {
            @include component-x-padding;
            line-height: 1.75rem;
            margin-bottom: 0.625rem;
          }

          .add-question-instruction-text {
            @include component-x-padding;
            line-height: 1.375rem;
            color: #00000073;
            margin-bottom: 2.188rem;
          }

          .question-add-container {
            width: 100%;

            .question-add {
              @include component-x-padding;
              display: flex;
              min-height: 4.75rem;
              width: 100%;
              align-items: center;

              .drag-icon {
                margin-right: 1.563rem;
              }

              .multiple-choice-checkbox-icon-container {
                width: 46px;
                height: 46px;
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.75rem;
              }

              .question-text {
                flex-grow: 1;
                margin-right: 0.75rem;

                .question-type {
                  font-family: $main-font-family;
                  line-height: 1.375rem;
                }

                .question-description {
                  font-family: $main-font-family;
                  line-height: 1.375rem;
                  color: #00000073;
                }
              }

              .add-button {
                transition: none;
                opacity: 0;
              }
            }

            .question-add:hover {
              background-color: $primary-color;

              svg path {
                stroke: white;
              }

              .multiple-choice-checkbox-icon-container {
                background-color: #4d20d4;
              }

              .question-text {
                .question-type {
                  color: white;
                }

                .question-description {
                  color: white;
                }
              }

              .add-button {
                opacity: 100;
              }
            }
          }
        }

        @media screen and (max-width: map-get($grid-breakpoints, xl)) {
          .question-creation-container {
            top: 0.375rem;
            position: static;
            padding-top: 2.5rem;
            margin-top: 0;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            padding-bottom: 1.5rem;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }

  .question-types-dropdown-menu {
    border-style: solid;
    border-width: 0.063rem;
    border-color: #d9d9d9;

    .menu-item {
      text-transform: unset;
    }
  }
}
