@import 'src/variables';

body {
  font-family: $main-font-family;
}

.score-total {
  min-width: 10rem;
  min-height: 2rem;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.quiz-footer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.date-wrapper {
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 2px 5px;
}

.ant-tooltip-inner, .pre-wrap {
  white-space: pre-wrap;
}

.ant-card-loading-block {
  background: $light-purple;
}

.ant-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-title {
      background: $light-purple;
    }
  }
}

.color-danger {
  color: $danger;
}

.border-radius-0 {
  border-radius: 0;
}

.float-right {
  float: right;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.field-addon {
  display: flex;
  gap: 0.5rem;
}

@mixin component-x-padding() {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
