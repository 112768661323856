@import 'src/variables';

.quizzeria-preview {
  .ant-row {
    .deeplink-buttons-container {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}
