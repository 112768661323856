@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import url('https://fonts.googleapis.com/css?family=Inter');

@page-min-width: 90vw;
@page-width: 90rem;
@background-color: #faf8ff;

.component-width () {
  width: @page-width;
  max-width: @page-min-width;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1.ant-typography {
  font-weight: normal !important;
}

section.ant-layout {
  min-height: 100vh;
}

ul.ant-pagination {
  margin-top: 1.5rem;
}

.quizzeria-component-width {
  .component-width();
}

.quizzeria-content {
  .component-width();
  padding-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.container-col {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.ant-layout-header {
  background-color: transparent;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.text-base {
  font-size: 1rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-xl {
  font-size: 1.25rem;
}

@primary-color: #6B33F9;@btn-default-border: #6B33F9;@btn-default-color: @primary-color;@tag-font-size: 0.813rem;