@import 'src/variables';
@import 'src/global';

$edit-quiz-icon-size: 1.5rem;

.quiz-cover {
  position: relative;

  .image-container {
    position: absolute;
    height: 100%;
    width: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    }
  }

  .quiz-detail {
    @include component-x-padding;
    padding-top: 2.5rem;
    margin-bottom: 1rem;

    hr {
      border-top: 0.063rem solid $separator-color;
    }

    .subject-tag {
      font-family: Inter, serif;
      line-height: 20px;
      margin-bottom: 0.5rem;
    }

    .subject-area-text {
      line-height: 1.75rem;
      color: $primary-color;
    }

    .quiz-title {
      font-size: 1.875rem;
      line-height: 2.375rem;
    }

    .info-tag {
      font-family: Inter, serif;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    .edit-quiz-button {
      position: absolute;
      top: 2.05rem;
      right: 0;

      svg {
        width: $edit-quiz-icon-size;
        height: $edit-quiz-icon-size;
        color: $icon-color;
      }
    }

    .quiz-description {
      line-height: 1.375rem;
      margin-bottom: 10rem;
    }

    .subject-container {
      margin-bottom: 0.5rem;
    }

    .divider {
      margin-top: 2rem;
    }
  }
}