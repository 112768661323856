@import 'src/variables';

@mixin answer-background {
  &.correct {
    background-color: $success-light;
  }

  &.partially-correct {
    background-color: $warning-light;
  }

  &.incorrect {
    background-color: $danger-light;
  }
}

.question-detail-view {
  input.choice-element {
    @include answer-background;
  }

  .choice-element:not(input) {
    margin-bottom: 0.625rem;
    padding: 0 0.438rem;

    @include answer-background;
  }

  .choice-result {
    position: absolute;
    z-index: 1;
    left: 1.063rem;
    top: 0.625rem;
  }

  .choice-result.text-based {
    position: initial;
  }

  .choices {
    .choice-index-letter {
      width: 1.875rem;
      height: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      border-style: solid;
      border-color: #f0f0f0;
      color: black;
    }

    .choice-index-letter-correct {
      background-color: $secondary-color;
      color: white;
    }

    .choice-string-col {
      color: #000000D9;
    }

    .choice-string-col-correct {
      color: $secondary-color;
    }
  }
}

.question-detail-view.correct {
  background: rgba(86, 203, 128, 0.05);
  border-color: rgba(86, 203, 128, 0.1);

  span.ant-tag,
  span.ant-avatar {
    border: 1px solid rgba(86, 203, 128, 0.35) !important;
    background: rgba(86, 203, 128, 0.1) !important;
    color: grey;
  }
}

.question-detail-view.partially-correct {
  background: rgba(250, 203, 122, 0.1);
  border-color: rgba(250, 203, 122, 0.2);

  span.ant-tag,
  span.ant-avatar {
    border: 1px solid rgba(250, 203, 122, 0.45) !important;
    background: rgba(250, 203, 122, 0.15) !important;
    color: grey;
  }
}

.question-detail-view.incorrect {
  background: rgba(240, 118, 86, 0.05);
  border-color: rgba(240, 118, 86, 0.1);

  span.ant-tag,
  span.ant-avatar {
    border: 1px solid rgba(240, 118, 86, 0.35) !important;
    background: rgba(240, 118, 86, 0.1) !important;
    color: grey;
  }
}
