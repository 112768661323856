@import 'src/variables';

.base-question {
  min-height: 8.125rem;
  background-color: white;
  padding: 1.5rem;

  .sequence-number-col {

    .sequence-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 3.125rem;
      height: $question-header-height;
      line-height: 1.75rem;
      background-color: $primary-color;
      color: white;

      &.correct {
        background-color: $success;
      }

      &.partially-correct {
        background-color: $warning;
      }

      &.incorrect {
        background-color: $danger;
      }
    }
  }
}
