@import 'src/variables';

.question-controls {
  height: $question-header-height;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;

  .down-icon {
    margin-right: 1.125rem;
  }

  a {
    line-height: normal;

    .dropdown-icon {
      margin-left: 2.313rem;
    }
  }

}