@import 'src/variables';

$card-image-left-margin: 0.8rem;

.ant-card.quiz-card {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 26.5rem;

  .title {
    margin-top: 0.8rem;
    font-style: normal;
    line-height: 1.5rem;
    color: black;
  }

  .ant-card-meta-description {
    height: 1.56rem;
  }

  .subject-area-text {
    color: $primary-color;
    font-style: normal;
    line-height: 1.375rem;
    letter-spacing: 0;
    text-align: center;
  }

  .subject-area-icon {
    margin-bottom: 4px;
  }

  .description {
    line-height: 1.375rem;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin: 0 0 1.3rem;
  }

  .author {
    margin-bottom: 0.6rem;
    line-height: 1.375rem;

    .author-icon {
      margin-top: 0.25rem;

      &.hidden {
        visibility: hidden;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 0.75rem 0;
  }

  .date-wrapper {
    position: absolute;
    top: 0.75rem;
    right: 0;
  }

  .card-cover-tags {
    position: absolute;
    left: $card-image-left-margin;
    top: 0.8rem;

    .score-maximum {
      width: fit-content;
      font-family: Inter, serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: bold;
      line-height: 1.25rem;
    }

    .published-tag {
      line-height: 1.25rem;
      font-family: Inter, serif;
    }
  }

  .menu-dropdown {
    position: absolute;
    font-size: 2rem;
    right: 1rem;
    top: 0.4rem;
  }

  .ant-card-body {
    margin-top: -1.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    padding-top: 2rem;
  }

  .card-footer {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .buttons {
    width: 100%;
  }

  .ant-card-cover {
    height: 11.25rem;

    .card-image-container {
      height: 100%;
      position: relative;

      .ant-image {
        height: 100%;
        width: inherit;

        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }

      .subjects {
        position: absolute;
        bottom: 1rem;
        left: $card-image-left-margin;

        .subject {
          max-width: 4.563rem;

          .ant-typography {
            width: 100%;
            color: white;
          }
        }
      }
    }
  }
}
