@import 'src/variables';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.question-save-form {
  background-color: white;

  .question-input {
    p {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    textarea {
      min-height: 5rem;
    }

  .textarea-buttons {
    position: absolute;
    right: 1.875rem;
    opacity: .7;
    z-index: 1;

    .textarea-button {
      cursor: pointer;

      svg {
        width: 1rem !important;
        height: 1rem !important;
        margin-top: .3rem;
      }
    }

  }

    .preview {
      margin-top: .5rem;
      background: #eaeaead6;
      padding: .5rem;
      border-radius: .1rem;
    }
  }

  .divider-color {
    border-top-color: rgba(0, 0, 0, 0.1);
  }

  .anticon {
    svg {
      width: 1rem;
      height: 1rem;
      color: $icon-color;
    }
  }

  .number-input {
    max-width: 2.5rem;
  }

  .ant-input-number {
    .anticon {
      svg {
        width: initial;
        height: initial;
      }
    }
  }

  .ant-upload.ant-upload-drag {
    border-radius: 0;

    .ant-upload {
      padding: 2rem 0;
    }

    .ant-upload-drag-icon {
      margin-bottom: 0.5rem;
    }
  }

  .question-row {
    .close-form-button {
      position: absolute;
      right: 2.5rem;
    }

    .icons-and-image-col {
      .ant-row {
        .image-col {
          margin-top: 0.188rem;
          text-align: center;
        }

        .icons-and-checkbox-col {
          margin-top: 0.188rem;

          .ant-row {
            .checkbox-col {
              margin-top: 0.188rem;
            }

            .icons-col {
              .ant-space {
                .points-label {
                  margin-top: 0.188rem;
                }

                .points-form-item {
                  text-align: center;

                  .number-input {
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    .question-info {
      height: $question-header-height;
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .question-type {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 14px;
      }

      .question-hint {
        font-size: 14px;
        color: #00000073;
        margin-bottom: 0;
      }
    }
  }

  .horizontal-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .settings-container {
    margin: 1.5rem;
  }

  .choices-container {
    margin: 1.5rem;
    .choices-header-row {
      margin-bottom: 0.625rem;

      .choice-options-col {
        color: #999999;
        text-decoration: none;
        font-family: OpenSans-Regular, Arial, serif;
        font-size: 14pt;
      }

      .correct-col {
        text-align: center;
        color: #999999;
        text-decoration: none;
        font-family: OpenSans-Regular, Arial, serif;
        font-size: 12pt;
      }
    }

    .choice-label {
      margin-bottom: 0.5rem;
    }

    .text-row {
      margin-bottom: 1.5rem;

      .ant-row {
        margin-bottom: 0;
      }

      .remove-choice {
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .choice-row {
      .close-icon-col {
        height: 2.5rem;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0.313rem;

        .anticon {
          svg {
            width: 1.125rem;
            height: 1.125rem;
          }
        }
      }

      .choice {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;

        .choice-input {
          flex-grow: 1;

          .ant-row {
            margin-bottom: 0;
          }
        }

        .choice-options {
          display: flex;
          width: 12rem;
          padding: 0 1rem;
          align-items: center;

          .remove-choice {
            display: flex;
            margin-right: 1rem;
            padding: 0.5rem;
            cursor: pointer;
            transition: 0.5s;

            &:hover {
              opacity: 0.7;
            }
          }
          .choice-right-side {
            display: flex;
            min-width: 6rem;

            .ant-row {
              margin-bottom: 0;
            }
          }
        }
      }

      .right-col {
        text-align: center;

        .ant-row {
          .ant-col {
            .ant-form-item-control-input {
              height: 2.5rem;

              .ant-form-item-control-input-content {
                margin-right: 0.75rem;
              }
            }
          }
        }
      }
    }

    .choices-footer-row {
      margin-top: 15px;
      margin-bottom: 15px;

      button {
        font-size: 12pt;
        height: 40px;
      }
    }
  }
}
