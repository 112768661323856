$separator-color: #dddddd;
$icon-color: #7d7d7d;
$gray: #d9d9d9;
$primary-color: #6b33f9;
$secondary-color: #00a351;
$blue-primary: #1890ff;
$main-font-family: Work Sans, serif;
$light-purple: #f3eeff;
$success: #00A351;
$warning: #FFC53D;
$danger: #FF4D4F;

$success-light: #CCE3D4;
$warning-light: #FFFBE6;
$danger-light: #FFF1F0;

$question-header-height: 3.125rem;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1135px,
        xl: 1200px,
        xxl: 1600px,
);
