@import 'src/variables';

.base-question-view {
  .anticon {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: $icon-color;
    }
  }

  .edit-icon {
    position: absolute;
    right: 0.625rem;
  }

  .ant-avatar {
    color: black !important;
  }

  .input-col {
    flex-grow: 1;
    padding-left: 0.5rem;

    .divider-color {
      border-top-color: rgba(0, 0, 0, 0.1);
    }

    .question-info {
      min-height: $question-header-height;
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .question-type {
        font-size: 14px;
        line-height: 22px;
        color: #00000073;
      }

      .question {
        line-height: 1.75rem;

        .react-mathjax-preview-result {
          word-break: break-word;
        }

        .points-container {
          display: inline-flex;
          height: 1.75rem;
          align-items: center;
          vertical-align: bottom;
        }
      }

      img {
        width: 100%;
      }
    }

    .choices {
      color: #999999;
      text-decoration: none;

      .choice-string-col {
        line-height: 1.375rem;
      }

      .ant-radio-group {
        width: 100%;
      }

      .ant-row {
        .choice-string-col,
        .choice-wrapper {
          word-break: break-all;
          margin-bottom: 1.25rem;
        }
      }
    }
  }

  .edit-button-col {
    padding-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      border-radius: 0;
    }
  }

  .image-and-icons-col {
    .image-and-icons-row {
      height: 100%;

      .icons-container-col {
        .icons-row {
          height: 100%;
          align-content: space-between;

          .upper-icons-space {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

.edit-icon-container {
  position: absolute;
  right: 0;
}

.selection-footer-container {
  margin-right: -1rem;
}
